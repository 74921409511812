import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import RequestQuote from '../components/RequestQuote'

const DryVanTruckloadPage = ({ data }) => {
  const postNode = {
    title: `Dry Van Truckload - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`DryVanTruckload - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Dry Van Truckload</PageTitle>
        <LargeP>
          From non-perishable foods and beverages to textile and clothing items,
          plastic and building products and other dry goods we can deliver your
          dry van needs.
        </LargeP>
        <p>
          Whether your shipment is local or over-the-road routes, our dry van
          options can accommodate. We can keep your products safe from damage or
          theft. Over 35 years of experience has given us the connections and
          the know how to deliver what you need when you need it delivered with
          state of the art technology helping to ensure your peace of mind along
          the way.
        </p>
        <RequestQuote />
        <h4>Dry Van Benefits</h4>
        <FlexList>
          <FlexItem>Dependable On-time Service</FlexItem>
          <FlexItem>SmartWay Transport Partner</FlexItem>
          <FlexItem>Competitive Priced Transportation</FlexItem>
          <FlexItem>Year Round Dedicated Lanes</FlexItem>
          <FlexItem>Financial Strength</FlexItem>
          <FlexItem>Flexible Capacity</FlexItem>
          <FlexItem>Asset and Non-Asset Based</FlexItem>
          <FlexItem>Dedicated & Friendly Customer Service </FlexItem>
          <FlexItem>On Call 24/7 Service</FlexItem>
          <FlexItem>Award Winning Safety</FlexItem>
          <FlexItem>Trailer Drop and Spot Availability</FlexItem>
          <FlexItem>EDI Capability</FlexItem>
          <FlexItem>Online Load Posting</FlexItem>
          <FlexItem>Online Document Retrieval</FlexItem>
          <FlexItem>Electronic Wire Funds Transfer</FlexItem>
          <FlexItem>Customer Reporting</FlexItem>
          <FlexItem>Online Load Tracking</FlexItem>
          <FlexItem>Qualcomm Communication</FlexItem>
          <FlexItem>Electronic Logging</FlexItem>
          <FlexItem>In-Cab Scanning</FlexItem>
          <FlexItem>Route Optimization</FlexItem>
          <FlexItem>Modern & Fuel Efficient Equipment</FlexItem>
          <FlexItem>Full Service Maintenance Facility</FlexItem>
          <FlexItem>California Air Resources Board (CARB) Fleet</FlexItem>
          <FlexItem>2017 and Newer Tractors</FlexItem>
          <FlexItem>53’ 102 Air Ride Trailers</FlexItem>
          <FlexItem>Haul up to 45,000 pounds</FlexItem>
          <FlexItem>State-of-the-Art Trailer Tracking</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default DryVanTruckloadPage
